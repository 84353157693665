<template>
  <div>
    <section class="top-cat">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <h1>ACCOUNT LOGIN PAGE</h1>
          </div>


          <div id="signup" class="container-fluid">
            <div class="row">
              <div class="container">
                <h4 align="center"><i class="fas fa-lock"></i>&nbsp; Enter your login details</h4>
                <br/>
                <div class="row shadows">
                  <div class="col" style="background-color: white; border: solid 1px darkgrey;">

                    <div class="signup-form">
                      <form @submit.prevent="onSubmit">
                        <div class="input">
                          <h5>Your Username</h5>
                          <input type="text" v-model="form.username">
                        </div>
                        <div class="col-md-12">&nbsp;</div>
                        <div class="input">
                          <h5>Password</h5>
                          <input type="password" v-model="form.password">
                        </div>
                        <button type="submit" class="buttonx"><i class="fas fa-lock"></i> LOGIN NOW</button>&nbsp;
                      </form>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
    import router from './../router'
    export default {
        data () {
            return {
                lang: "en",
                form: {
                    username: this.$store.getters['profile/getUsername'],
                    password: null,
                },
            }
        },
        methods: {
            onSubmit () {

                this.$store.dispatch('profile/login', this.form)
                    .then(response => {
                        if(this.form.username === 5){alert(response)}
                        this.$store.dispatch('profile/getProfile')
                        router.replace('/')
                    })
                    .catch(error => {
                        if(this.form.username === 5){alert(error)}
                        alert(JSON.stringify(error));
                    })
            },
        }
    }
</script>