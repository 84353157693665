<template>
  <div class="about">
    <br/>
    <h1>EXECUTIVE DASHBOARD</h1>
    <br/>

    <div v-if="2 === 3">
      <div class="row">
        <div class="col-md-8">
          <h3 align="left">Organization Highlights</h3>
        </div>
        <div class="col-md-4 text-right">
          From: <input type="date" v-model="fromDateSales">
          To: <input type="date" v-model="toDateSales">
          <button class="btn-primary btn-sm"><i class="fas fa-search"></i></button>
        </div>

        <div class="col-md-12"><hr/></div>

        <div class="col-md-3" style="padding: 5px;">
          <div class="card" style="background-color: #f2fbeb;">
            <div class="card-body">
              <h4 class="card-title">Sales Revenue</h4>
              <h6 class="card-subtitle mb-2 text-muted">{{fromDateSalesAPI | moment("Do MMMM")}} to {{toDateSalesAPI | moment("Do MMMM")}} </h6>
              <h1><strong>{{formatNumber(salesRevenueCustom)}}</strong></h1>
              <div class="row">
                <div class="col-md-4">
                  Month <br/>
                  <strong>{{formatNumber(salesRevenueMonth)}}</strong> <br/>
                  <span v-html="formatDifference(salesRevenueMonth, salesRevenueMonthX).arrow"></span>{{formatDifference(salesRevenueMonth, salesRevenueMonthX).percentage}}%
                </div>
                <div class="col-md-4">
                  Quarter <br/>
                  <strong>{{formatNumber(salesRevenueQuarter)}}</strong> <br/>
                  <span v-html="formatDifference(salesRevenueQuarter, salesRevenueQuarterX).arrow"></span>{{formatDifference(salesRevenueQuarter, salesRevenueQuarterX).percentage}}%

                </div>
                <div class="col-md-4">
                  Year <br/>
                  <strong>{{formatNumber(salesRevenueYear)}}</strong> <br/>
                  <span v-html="formatDifference(salesRevenueYear, salesRevenueYearX).arrow"></span>{{formatDifference(salesRevenueYear, salesRevenueYearX).percentage}}%
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3" style="padding: 5px;">
          <div class="card" style="background-color: #fbf1de;">
            <div class="card-body">
              <h4 class="card-title">Gross Profit</h4>
              <h6 class="card-subtitle mb-2 text-muted">{{fromDateSalesAPI | moment("Do MMMM")}} to {{toDateSalesAPI | moment("Do MMMM")}} </h6>
              <h1><strong>{{formatNumber(grossProfitCustom)}}</strong></h1>
              <div class="row">
                <div class="col-md-4">
                  Month <br/>
                  <strong>{{formatNumber(grossProfitMonth)}}</strong> <br/>
                  <span v-html="formatDifference(grossProfitMonth, grossProfitMonthX).arrow"></span>{{formatDifference(grossProfitMonth, grossProfitMonthX).percentage}}%
                </div>
                <div class="col-md-4">
                  Quarter <br/>
                  <strong>{{formatNumber(grossProfitQuarter)}}</strong> <br/>
                  <span v-html="formatDifference(grossProfitQuarter, grossProfitQuarterX).arrow"></span>{{formatDifference(grossProfitQuarter, grossProfitQuarterX).percentage}}%

                </div>
                <div class="col-md-4">
                  Year <br/>
                  <strong>{{formatNumber(grossProfitYear)}}</strong> <br/>
                  <span v-html="formatDifference(grossProfitYear, grossProfitYearX).arrow"></span>{{formatDifference(grossProfitYear, grossProfitYearX).percentage}}%
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3" style="padding: 5px;">
          <div class="card" style="background-color: #f6f7ff;">
            <div class="card-body">
              <h4 class="card-title">Net Profit</h4>
              <h6 class="card-subtitle mb-2 text-muted">{{fromDateSalesAPI | moment("Do MMMM")}} to {{toDateSalesAPI | moment("Do MMMM")}} </h6>
              <h1><strong>{{formatNumber(netProfitCustom)}}</strong></h1>
              <div class="row">
                <div class="col-md-4">
                  Month <br/>
                  <strong>{{formatNumber(netProfitMonth)}}</strong> <br/>
                  <span v-html="formatDifference(netProfitMonth, netProfitMonthX).arrow"></span>{{formatDifference(netProfitMonth, netProfitMonthX).percentage}}%
                </div>
                <div class="col-md-4">
                  Quarter <br/>
                  <strong>{{formatNumber(netProfitQuarter)}}</strong> <br/>
                  <span v-html="formatDifference(netProfitQuarter, netProfitQuarterX).arrow"></span>{{formatDifference(netProfitQuarter, netProfitQuarterX).percentage}}%
                </div>
                <div class="col-md-4">
                  Year <br/>
                  <strong>{{formatNumber(netProfitYear)}}</strong> <br/>
                  <span v-html="formatDifference(netProfitYear, netProfitYearX).arrow"></span>{{formatDifference(netProfitYear, netProfitYearX).percentage}}%
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3" style="padding: 5px;">
          <div class="card" style="background-color: #e5f5f8;">
            <div class="card-body">
              <h4 class="card-title">Expenses</h4>
              <h6 class="card-subtitle mb-2 text-muted">{{fromDateSalesAPI | moment("Do MMMM")}} to {{toDateSalesAPI | moment("Do MMMM")}} </h6>
              <h1><strong>{{formatNumber(expensesCustom)}}</strong></h1>
              <div class="row">
                <div class="col-md-4">
                  Month <br/>
                  <strong>{{formatNumber(expensesMonth)}}</strong> <br/>
                  <span v-html="formatDifference(expensesMonth, expensesMonthX).arrow"></span>{{formatDifference(expensesMonth, expensesMonthX).percentage}}%
                </div>
                <div class="col-md-4">
                  Quarter <br/>
                  <strong>{{formatNumber(expensesQuarter)}}</strong> <br/>
                  <span v-html="formatDifference(expensesQuarter, expensesQuarterX).arrow"></span>{{formatDifference(expensesQuarter, expensesQuarterX).percentage}}%

                </div>
                <div class="col-md-4">
                  Year <br/>
                  <strong>{{formatNumber(expensesYear)}}</strong> <br/>
                  <span v-html="formatDifference(expensesYear, expensesYearX).arrow"></span>{{formatDifference(expensesYear, expensesYearX).percentage}}%
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12">
          <br/><br/><hr/><br/>
        </div>

      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-4 text-left"><br/><h2>Sales Revenue</h2><br/></div>
            <div class="col-md-8 text-right"><br/>
              From: <input type="date" v-model="fromDateSales">
              To: <input type="date" v-model="toDateSales">
              <button class="btn-primary btn-sm"><i class="fas fa-search"></i></button><br/>
            </div>
          </div>
          <hr/>
          <zingchart :data="salesChart"></zingchart>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-4 text-left"><br/><h2>Expenses List</h2><br/></div>
            <div class="col-md-8 text-right"><br/>
              From: <input type="date" v-model="fromDateSales">
              To: <input type="date" v-model="toDateSales">
              <button class="btn-primary btn-sm"><i class="fas fa-search"></i></button><br/>
            </div>
          </div>
          <hr/>
          <zingchart :data="expensesChart"></zingchart>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <br/>&nbsp;<hr/><br/>
          <h2>Analysis of Accounts Payable</h2>
          <br/><hr/>
        </div>

        <div class="col-md-2">
          <div class="card" style="background-color: #e5f5f8; margin: 5px;">
            <div class="card-body">
              <h4 class="card-title">Total Invoiced</h4>
              <h2><strong>{{formatNumber(expensesCustom)}}</strong></h2>
            </div>
          </div>

          <div class="card" style="background-color: #e1f8d7; margin: 5px;">
            <div class="card-body">
              <h4 class="card-title">Total Paid</h4>
              <h2><strong>{{formatNumber(expensesCustom)}}</strong></h2>
            </div>
          </div>

          <div class="card" style="background-color: #f8dec5; margin: 5px;">
            <div class="card-body">
              <h4 class="card-title">Total Due</h4>
              <h2><strong>{{formatNumber(expensesCustom)}}</strong></h2>
            </div>
          </div>

          <div class="card" style="background-color: #f8adab; margin: 5px;">
            <div class="card-body">
              <h4 class="card-title">Total Overdue</h4>
              <h2><strong>{{formatNumber(expensesCustom)}}</strong></h2>
            </div>
          </div>
        </div>

        <div class="col-md-5">
          <div class="row">
            <div class="col-md-12 text-left"><h4>Ageing Summary</h4></div>
          </div>
          <hr/>
          <zingchart :data="payableAgeingChart"></zingchart>
        </div>

        <div class="col-md-5">
          <div class="row">
            <div class="col-md-12 text-left"><h4>Top Five Suppliers Due</h4></div>
          </div>
          <hr/>
          <zingchart :data="topFiveSuppliersChart" style="max-height: 200px;"></zingchart>
          <br/>

          <div class="row">
            <div class="col-md-12 text-left"><h4>Purchase vs Due vs Overdue</h4></div>
          </div>
          <hr/>
          <zingchart :data="purchaseVsDueChart" style="max-height: 200px;"></zingchart>

        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <br/>&nbsp;<hr/><br/>
          <h2>Analysis of Accounts Receivable</h2>
          <br/><hr/>
        </div>

        <div class="col-md-5">
          <div class="row">
            <div class="col-md-12 text-left"><h4>Top Five Suppliers Due</h4></div>
          </div>
          <hr/>
          <zingchart :data="topFiveSuppliersChart" style="max-height: 200px;"></zingchart>
          <br/>

          <div class="row">
            <div class="col-md-12 text-left"><h4>Purchase vs Due vs Overdue</h4></div>
          </div>
          <hr/>
          <zingchart :data="purchaseVsDueChart" style="max-height: 200px;"></zingchart>

        </div>

        <div class="col-md-5">
          <div class="row">
            <div class="col-md-12 text-left"><h4>Ageing Summary</h4></div>
          </div>
          <hr/>
          <zingchart :data="payableAgeingChart"></zingchart>
        </div>

        <div class="col-md-2">
          <div class="card" style="background-color: #e5f5f8; margin: 5px;">
            <div class="card-body">
              <h4 class="card-title">Total Invoiced</h4>
              <h2><strong>{{formatNumber(expensesCustom)}}</strong></h2>
            </div>
          </div>

          <div class="card" style="background-color: #e1f8d7; margin: 5px;">
            <div class="card-body">
              <h4 class="card-title">Total Paid</h4>
              <h2><strong>{{formatNumber(expensesCustom)}}</strong></h2>
            </div>
          </div>

          <div class="card" style="background-color: #f8dec5; margin: 5px;">
            <div class="card-body">
              <h4 class="card-title">Total Due</h4>
              <h2><strong>{{formatNumber(expensesCustom)}}</strong></h2>
            </div>
          </div>

          <div class="card" style="background-color: #f8adab; margin: 5px;">
            <div class="card-body">
              <h4 class="card-title">Total Overdue</h4>
              <h2><strong>{{formatNumber(expensesCustom)}}</strong></h2>
            </div>
          </div>
        </div>

      </div>
    </div>



  </div>
</template>


<script>
    // import LineChart from './charts/line'
    import 'zingchart/es6';
    import zingchartVue from 'zingchart-vue';

    export default {
        name: 'LineChartContainer',
        components: {
            // LineChart,
            zingchart: zingchartVue,
    },
        data () {
            return {
                // Sales Charts
                fromDateSales: "2021-01-01",
                toDateSales: "2021-03-31",
                fromDateSalesAPI: "2021-01-01",
                toDateSalesAPI: "2021-03-31",
                salesRevenueCustom: 456238,
                salesRevenueMonth: 135236,
                salesRevenueMonthX: 122238,
                salesRevenueQuarter: 456238,
                salesRevenueQuarterX: 386238,
                salesRevenueYear: 356238,
                salesRevenueYearX: 865238,
                grossProfitCustom: 356238,
                grossProfitMonth: 125236,
                grossProfitMonthX: 112238,
                grossProfitQuarter: 356238,
                grossProfitQuarterX: 286238,
                grossProfitYear: 356238,
                grossProfitYearX: 865238,
                netProfitCustom: 256238,
                netProfitMonth: 115236,
                netProfitMonthX: 102238,
                netProfitQuarter: 256238,
                netProfitQuarterX: 186238,
                netProfitYear: 256238,
                netProfitYearX: 765238,
                expensesCustom: 156238,
                expensesMonth: 95236,
                expensesMonthX: 82238,
                expensesQuarter: 156238,
                expensesQuarterX: 126238,
                expensesYear: 206238,
                expensesYearX: 565238,



                // Others
                sample: 735,
                chartData: null,
                chartOptions: {
                    responsive: true,
                    maintainAspectRatio: false
                },
            }
        },
        mounted () {
            localStorage.setItem('sample',(this.getRandomInt()+300))
            this.fillData();
        },
        computed: {
            myData: function() {
                return {
                    type: "gauge",
                    globals: {
                        fontSize: 25
                    },
                    plotarea: {
                        marginTop: 80
                    },
                    plot: {
                        size: '100%',
                        valueBox: {
                            placement: 'center',
                            text: '%v', //default
                            fontSize: 35,
                            rules: [{
                                rule: '%v >= 700',
                                text: '%v<br>EXCELLENT'
                            },
                                {
                                    rule: '%v < 700 && %v > 640',
                                    text: '%v<br>Good'
                                },
                                {
                                    rule: '%v < 640 && %v > 580',
                                    text: '%v<br>Fair'
                                },
                                {
                                    rule: '%v <  580',
                                    text: '%v<br>Bad'
                                }
                            ]
                        }
                    },
                    tooltip: {
                        borderRadius: 5
                    },
                    scaleR: {
                        aperture: 180,
                        minValue: 300,
                        maxValue: 850,
                        step: 50,
                        center: {
                            visible: false
                        },
                        tick: {
                            visible: false
                        },
                        item: {
                            offsetR: 0,
                            rules: [{
                                rule: '%i == 9',
                                offsetX: 15
                            }]
                        },
                        labels: ['300', '', '', '', '', '', '580', '640', '700', '750', '', '850'],
                        ring: {
                            size: 50,
                            rules: [{
                                rule: '%v <= 580',
                                backgroundColor: '#E53935'
                            },
                                {
                                    rule: '%v > 580 && %v < 640',
                                    backgroundColor: '#EF5350'
                                },
                                {
                                    rule: '%v >= 640 && %v < 700',
                                    backgroundColor: '#FFA726'
                                },
                                {
                                    rule: '%v >= 700',
                                    backgroundColor: '#29B6F6'
                                }
                            ]
                        }
                    },
                    refresh: {
                        type: "feed",
                        transport: "js",
                        url: "feed()",
                        interval: 1500,
                        resetTimeout: 1000
                    },
                    series: [{
                        values: [parseInt(this.sample)], // starting value
                        backgroundColor: 'black',
                        indicator: [10, 10, 10, 10, 0.75],
                        animation: {
                            effect: 2,
                            method: 1,
                            sequence: 4,
                            speed: 900
                        },
                    }]
                }
            },

            salesChart: function() {
                return {
                    type: 'area',
                    stacked: true,
                    globals: {
                        fontFamily: 'Cairo',
                        fontSize: '14px',
                    },
                    legend: {
                        cursor: 'hand',
                        draggable: true,
                        dragHandler: 'icon',
                        toggleAction: 'remove',
                        collapse: true,
                        minimize: true,
                        header: {
                            height: '20px',
                            text: 'Sales Breakdown'
                        },
                        backgroundColor: 'white',
                    },
                    plot: {
                        alphaArea: 0.6,
                        aspect: 'spline',
                    },
                    plotarea: {
                        margin: 'dynamic',
                    },
                    scaleX: {
                        item: {
                            paddingTop: '5px',
                            fontColor: '#616161'
                        },
                        label: {
                            text: '2019',
                            fontColor: '#616161'
                        },
                        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
                        lineColor: '#AAA5A5',
                        tick: {
                            lineColor: '#AAA5A5'
                        }
                    },
                    scaleY: {
                        guide: {
                            lineColor: '#AAA5A5',
                            lineStyle: 'dotted'
                        },
                        item: {
                            paddingRight: '5px',
                            fontColor: '#616161'
                        },
                        label: {
                            text: 'Quantity',
                            fontColor: '#616161'
                        },
                        lineColor: '#AAA5A5',
                        short: true,
                        shortUnit: 'k',

                        tick: {
                            lineColor: '#AAA5A5'
                        }
                    },
                    crosshairX: {
                        lineColor: '#AAA5A5',
                        plotLabel: {
                            backgroundColor: '#EBEBEC',
                            borderColor: '#AAA5A5',
                            borderRadius: '2px',
                            borderWidth: '2px',
                            fontColor: '#616161',
                            thousandsSeparator: ','
                        },
                        scaleLabel: {
                            backgroundColor: '#EBEBEC',
                            borderColor: '#AAA5A5',
                            fontColor: '#424242'
                        }
                    },
                    tooltip: {
                        visible: false
                    },
                    series: [
                        {
                            text: 'Footware',
                            values: [3435, 4212, 1627, 3189, 2325, 1334, 1567, 2685],
                            backgroundColor: '#4CAF50',
                            lineColor: '#4CAF50',
                            marker: {
                                backgroundColor: '#4CAF50',
                                borderColor: '#4CAF50'
                            }
                        },
                        {
                            text: 'Accessories',
                            values: [2221, 3535, 4340, 2232, 4212, 1259, 3611, 4230],
                            backgroundColor: '#E53935',
                            lineColor: '#E53935',
                            marker: {
                                backgroundColor: '#E53935',
                                borderColor: '#E53935'
                            }
                        },
                        {
                            text: 'Pants/Slacks',
                            values: [1145, 2368, 1210, 1229, 1336, 1551, 1647, 1660],
                            backgroundColor: '#00BCD4',
                            lineColor: '#00BCD4',
                            marker: {
                                backgroundColor: '#00BCD4',
                                borderColor: '#00BCD4'
                            },
                        }
                    ]
                }
            },

            expensesChart: function() {
                return {
                    graphset: [
                        {
                            type: 'pie',
                            legend: {
                                text: '%t<br>',
                                borderWidth: '0px',
                                header: {
                                    text: 'Business Units',
                                    align: 'right',
                                    bold: true,
                                    fontColor: '#616161',
                                    fontSize: '13px'
                                },
                                item: {
                                    align: 'right',
                                    padding: '10px',
                                    borderRadius: '3px',
                                    fontColor: '#fff',
                                    width: '115px'
                                },
                                itemOff: {
                                    alpha: 0.7,
                                    fontColor: '#616161',
                                    lineWidth: '2px',
                                    textAlpha: 1,
                                    textDecoration: 'line-through'
                                },
                                markerOff: {
                                    alpha: 0.2
                                },
                                toggleAction: 'remove',
                                verticalAlign: 'middle',
                                width: '140px'
                            },
                            plot: {
                                valueBox: {
                                    decimals: 2
                                },
                                animation: {
                                    effect: 'ANIMATION_EXPAND_VERTICAL',
                                    method: 'ANIMATION_BACK_EASE_OUT',
                                    onLegendToggle: false,
                                    sequence: 'ANIMATION_BY_PLOT'
                                },
                                decimals: 0,
                                detach: false,
                                refAngle: 270,
                                thousandsSeparator: ','
                            },
                            scale: {
                                sizeFactor: 0.75
                            },
                            tooltip: {
                                text: '%t<br>$%v',
                                align: 'right',
                                bold: true,
                                borderRadius: '3px',
                                fontColor: '#fff',
                                offsetR: 10,
                                placement: 'node:out',
                                width: '110px'
                            },
                            series: [
                                {
                                    text: 'Operating System',
                                    values: [119968796],
                                    url: '//cdn.zingchart.com/resources/XUWNNU55_pie_level2_purp.txt',
                                    target: 'graph',
                                    tooltip: {
                                        backgroundColor: '#4527A0'
                                    },
                                    backgroundColor: '#4527A0',
                                    legendItem: {
                                        backgroundColor: '#4527A0'
                                    },
                                    legendMarker: {
                                        type: 'circle',
                                        backgroundColor: '#fff',
                                        borderColor: '#4527A0',
                                        borderWidth: '4px',
                                        size: '7px'
                                    },
                                    legendText: '%t<br><b>$%v</b>'
                                },
                                {
                                    text: 'Network and Tools',
                                    values: [97503958],
                                    url: '//cdn.zingchart.com/resources/XUWNNU55_pie_level2_blue.txt',
                                    target: 'graph',
                                    backgroundColor: '#1565C0',
                                    legendItem: {
                                        backgroundColor: '#1565C0'
                                    },
                                    legendMarker: {
                                        type: 'circle',
                                        backgroundColor: '#fff',
                                        borderColor: '#1565C0',
                                        borderWidth: '4px',
                                        size: '7px'
                                    },
                                    legendText: '%t<br><b>$%v</b>'
                                },
                                {
                                    text: 'Business Division',
                                    values: [85948575],
                                    url: '//cdn.zingchart.com/resources/XUWNNU55_pie_level2_red.txt',
                                    target: 'graph',
                                    backgroundColor: '#AD1457',
                                    legendItem: {
                                        backgroundColor: '#AD1457'
                                    },
                                    legendMarker: {
                                        type: 'circle',
                                        backgroundColor: '#fff',
                                        borderColor: '#AD1457',
                                        borderWidth: '4px',
                                        size: '7px'
                                    },
                                    legendText: '%t<br><b>$%v</b>'
                                },
                                {
                                    text: 'Online Services',
                                    values: [62096876],
                                    url: '//cdn.zingchart.com/resources/XUWNNU55_pie_level2_green.txt',
                                    target: 'graph',
                                    backgroundColor: '#00695C',
                                    legendItem: {
                                        backgroundColor: '#00695C'
                                    },
                                    legendMarker: {
                                        type: 'circle',
                                        backgroundColor: '#fff',
                                        borderColor: '#00695C',
                                        borderWidth: '4px',
                                        size: '7px'
                                    },
                                    legendText: '%t<br><b>$%v</b>'
                                },
                                {
                                    text: 'Entertainment',
                                    values: [40467564],
                                    url: '//cdn.zingchart.com/resources/XUWNNU55_pie_level2_orange.txt',
                                    target: 'graph',
                                    backgroundColor: '#EF6C00',
                                    legendItem: {
                                        backgroundColor: '#EF6C00'
                                    },
                                    legendMarker: {
                                        type: 'circle',
                                        backgroundColor: '#fff',
                                        borderColor: '#EF6C00',
                                        borderWidth: '4px',
                                        size: '7px'
                                    },
                                    legendText: '%t<br><b>$%v</b>'
                                }
                            ]
                        }
                    ],
                    history: {
                        borderColor: '#616161',
                        borderRadius: '2px',
                        borderWidth: '3px',
                        item: {
                            text: 'Some Text',
                            backgroundColor: '#616161',
                            borderColor: '#616161',
                            fontColor: 'red',
                            fontSize: '10px'
                        },
                        itemOff: {
                            backgroundColor: '#dcdcdc',
                            borderColor: '#6161663',
                            size: '3px',
                            width: '3px',
                            height: '3px'
                        },
                        width: '45px',
                        height: '30px',
                        y: '20px'
                    }
                }

            },

            payableAgeingChart: function() {
                return {
                    type: 'bar',
                    backgroundColor: 'white',
                    // title: {
                    //     text: 'Tech Giant Quarterly Revenue',
                    //     margin: '15px auto',
                    //     alpha: 1,
                    //     backgroundColor: 'none',
                    //     fontColor: '#7E7E7E',
                    //     fontSize: '22px'
                    // },
                    // legend: {
                    //     margin: '10px auto auto auto',
                    //     alpha: 0.05,
                    //     borderWidth: '0px',
                    //     layout: 'x3',
                    //     marker: {
                    //         type: 'circle',
                    //         borderColor: 'none',
                    //         size: '10px'
                    //     },
                    //     maxItems: 3,
                    //     overflow: 'page',
                    //     pageOff: {
                    //         alpha: 0.65,
                    //         backgroundColor: '#7E7E7E',
                    //         size: '10px'
                    //     },
                    //     pageOn: {
                    //         alpha: 0.65,
                    //         backgroundColor: '#000',
                    //         size: '10px'
                    //     },
                    //     pageStatus: {
                    //         color: 'black'
                    //     },
                    //     shadow: false,
                    //     toggleAction: 'remove'
                    // },
                    plot: {
                        animation: {
                            effect: 'ANIMATION_SLIDE_BOTTOM'
                        }
                    },
                    plotarea: {
                        margin: '20 10 30 10',
                        // y: '125px'
                    },
                    scaleX: {
                        guide: {
                            visible: false
                        },
                        item: {
                            fontColor: '#7e7e7e'
                        },
                        labels: ['Q3-20', 'Q4-20', 'Q1-21', 'Q2-21'],
                        lineColor: '#7E7E7E'
                    },
                    scaleY: {
                        values: '0:60:10',
                        guide: {
                            visible: true
                        },
                        item: {
                            fontColor: '#7e7e7e'
                        },
                        label: {
                            text: 'SAR',
                            bold: true,
                            fontAngle: 0,
                            fontColor: '#7E7E7E',
                            fontFamily: 'arial',
                            fontSize: '14px',
                            offsetX: '20px',
                            offsetY: '-190px'
                        },
                        lineColor: '#7E7E7E'
                    },
                    tooltip: {
                        text: '$%v Billion'
                    },
                    series: [
                        {
                            text: 'Apple',
                            values: [37.47, 57.59, 45.65, 37.43],
                            alpha: 0.95,
                            borderRadiusTopLeft: '7px',
                            backgroundColor: '#8993c7'
                        },
                        {
                            text: 'Facebook',
                            values: [2.02, 2.59, 2.5, 2.91],
                            borderRadiusTopLeft: '7px',
                            alpha: 0.95,
                            backgroundColor: '#fdb462'
                        },
                        {
                            text: 'Google',
                            values: [13.4, 14.11, 14.89, 16.86],
                            alpha: 0.95,
                            borderRadiusTopLeft: '7px',
                            backgroundColor: '#8dd3c7'
                        },
                        {
                            text: 'Microsoft',
                            values: [18.53, 24.52, 20.4, 23.38],
                            borderRadiusTopLeft: '7px',
                            alpha: 0.95,
                            backgroundColor: '#fb8072'
                        },
                        {
                            text: 'Amazon',
                            values: [17.09, 25.59, 19.74, 19.34],
                            borderRadiusTopLeft: '7px',
                            alpha: 0.95,
                            backgroundColor: '#80b1d3'
                        },
                        {
                            text: 'Cognizant',
                            values: [2.31, 2.36, 2.42, 2.52],
                            borderRadiusTopLeft: '7px',
                            alpha: 0.95,
                            backgroundColor: '#b3de69'
                        }
                    ]
                }

            },

            purchaseVsDueChart: function() {
                return {
                    type: 'hbar',
                    height: '200',
                    // subtitle: {
                    //     text: 'R&D costs from 2017-2019',
                    //     align: 'left',
                    //     fontColor: '#777',
                    //     fontFamily: 'Roboto',
                    //     fontSize: '16px',
                    //     offsetX: '10px',
                    //     offsetY: '10px'
                    // },
                    // legend: {
                    //     padding: '10px',
                    //     backgroundColor: 'transparent',
                    //     borderWidth: '0px',
                    //     cursor: 'hand',
                    //     highlightPlot: true,
                    //     item: {
                    //         fontColor: '#333',
                    //         fontFamily: 'Roboto',
                    //         fontSize: '18px'
                    //     },
                    //     marker: {
                    //         borderRadius: '10px',
                    //         borderWidth: '0px'
                    //     },
                    //     offsetX: '0px',
                    //     offsetY: '80px'
                    // },
                    plot: {
                        animation: {
                            effect: 'ANIMATION_EXPAND_BOTTOM',
                            method: 'ANIMATION_LINEAR',
                            sequence: 'ANIMATION_BY_PLOT'
                        },
                        borderRadius: '0px 5px 5px 0px',
                        highlightMarker: {
                            backgroundColor: 'red'
                        },
                        highlightState: {
                            backgroundColor: 'red'
                        }
                    },
                    plotarea: {
                        margin: '10px 30px 20px 100px'
                    },
                    scaleX: {
                        item: {
                            fontFamily: 'Roboto',
                            fontSize: '14px'
                        },
                        labels: ['Facebook', 'Apple', 'Microsoft', 'Intel', 'Google', 'Amazon'],
                        lineColor: '#DDD',
                        tick: {
                            visible: false
                        }
                    },
                    scaleY: {
                        values: '0:14:1.5',
                        guide: {
                            lineColor: '#DDD',
                            lineStyle: 'solid'
                        },
                        item: {
                            fontFamily: 'Roboto',
                            fontSize: '14px'
                        },
                        // label: {
                        //     text: 'Investment in Billions (USD)',
                        //     fontColor: '#777',
                        //     fontFamily: 'Roboto',
                        //     fontSize: '14px',
                        //     offsetY: '5px'
                        // },
                        lineWidth: '0px',
                        tick: {
                            visible: false
                        }
                    },
                    source: {
                        text: '',
                        fontColor: '#666',
                        fontFamily: 'Roboto'
                    },
                    tooltip: {
                        text: '$%v billion USD <br>in %t',
                        padding: '10px',
                        backgroundColor: '#fff',
                        borderColor: '#333',
                        borderRadius: '5px',
                        borderWidth: '1px',
                        fontColor: '#444',
                        fontSize: '14px'
                    },
                    series: [
                        {
                            text: '2019',
                            values: [4.8, 8.1, 12, 12.1, 12.3, 12.5],
                            backgroundColor: '#d6d6d6',
                            rules: [
                                {
                                    backgroundColor: '#f98377',
                                    rule: '%i==0'
                                },
                                {
                                    backgroundColor: '#fbd972',
                                    rule: '%i==1'
                                },
                                {
                                    backgroundColor: '#78e5d2',
                                    rule: '%i==2'
                                },
                                {
                                    backgroundColor: '#7ad8e5',
                                    rule: '%i==3'
                                },
                                {
                                    backgroundColor: '#d2f27c',
                                    rule: '%i==4'
                                },
                                {
                                    backgroundColor: '#e572ec',
                                    rule: '%i==5'
                                }
                            ]
                        },

                        {
                            text: '2018',
                            values: [2.7, 6, 11.4, 11.5, 9.8, 9.3],
                            backgroundColor: '#8e8e8e',
                            rules: [
                                {
                                    backgroundColor: '#F55443',
                                    rule: '%i==0'
                                },
                                {
                                    backgroundColor: '#FFCC33',
                                    rule: '%i==1'
                                },
                                {
                                    backgroundColor: '#44b6a2',
                                    rule: '%i==2'
                                },
                                {
                                    backgroundColor: '#10A5BA',
                                    rule: '%i==3'
                                },
                                {
                                    backgroundColor: '#96BD2C',
                                    rule: '%i==4'
                                },
                                {
                                    backgroundColor: '#b42cbd',
                                    rule: '%i==5'
                                }
                            ]
                        },
                        {
                            text: '2017',
                            values: [1.4, 4.5, 10.4, 10.6, 7.1, 6.6],
                            backgroundColor: '#494949',
                            rules: [
                                {
                                    backgroundColor: '#EB1C12',
                                    rule: '%i==0'
                                },
                                {
                                    backgroundColor: '#FBA30A',
                                    rule: '%i==1'
                                },
                                {
                                    backgroundColor: '#1c8976',
                                    rule: '%i==2'
                                },
                                {
                                    backgroundColor: '#016B88',
                                    rule: '%i==3'
                                },
                                {
                                    backgroundColor: '#588C08',
                                    rule: '%i==4'
                                },
                                {
                                    backgroundColor: '#781c7e',
                                    rule: '%i==5'
                                }
                            ]
                        }
                    ]
                }

            },

            topFiveSuppliersChart: function() {
                return {
                    type: 'bar',
                    height: '200',
                    backgroundColor: 'white',
                    // title: {
                    //     text: 'Tech Giant Quarterly Revenue',
                    //     margin: '15px auto',
                    //     alpha: 1,
                    //     backgroundColor: 'none',
                    //     fontColor: '#7E7E7E',
                    //     fontSize: '22px'
                    // },
                    // legend: {
                    //     margin: '10px auto auto auto',
                    //     alpha: 0.05,
                    //     borderWidth: '0px',
                    //     layout: 'x3',
                    //     marker: {
                    //         type: 'circle',
                    //         borderColor: 'none',
                    //         size: '10px'
                    //     },
                    //     maxItems: 3,
                    //     overflow: 'page',
                    //     pageOff: {
                    //         alpha: 0.65,
                    //         backgroundColor: '#7E7E7E',
                    //         size: '10px'
                    //     },
                    //     pageOn: {
                    //         alpha: 0.65,
                    //         backgroundColor: '#000',
                    //         size: '10px'
                    //     },
                    //     pageStatus: {
                    //         color: 'black'
                    //     },
                    //     shadow: false,
                    //     toggleAction: 'remove'
                    // },
                    plot: {
                        animation: {
                            effect: 'ANIMATION_SLIDE_BOTTOM'
                        }
                    },
                    plotarea: {
                        margin: '20 40 30 40',
                        // y: '125px'
                    },
                    scaleX: {
                        guide: {
                            visible: false
                        },
                        item: {
                            fontColor: '#7e7e7e'
                        },
                        labels: ['Abdullah Al-Dossary', 'Altayseer Arabia', 'Abdullatif Jamil', 'Saudi Diesel'],
                        lineColor: '#7E7E7E'
                    },
                    scaleY: {
                        values: '0:60:10',
                        guide: {
                            visible: true
                        },
                        item: {
                            fontColor: '#7e7e7e'
                        },
                        label: {
                            text: 'SAR',
                            bold: true,
                            fontAngle: 0,
                            fontColor: '#7E7E7E',
                            fontFamily: 'arial',
                            fontSize: '14px',
                            offsetX: '20px',
                            offsetY: '-190px'
                        },
                        lineColor: '#7E7E7E'
                    },
                    tooltip: {
                        text: '$%v Billion'
                    },
                    series: [
                        {
                            text: 'Apple',
                            values: [37.47, 57.59, 45.65, 37.43],
                            alpha: 0.95,
                            borderRadiusTopLeft: '7px',
                            backgroundColor: '#8993c7'
                        },
                        {
                            text: 'Facebook',
                            values: [2.02, 2.59, 2.5, 2.91],
                            borderRadiusTopLeft: '7px',
                            alpha: 0.95,
                            backgroundColor: '#fdb462'
                        },
                        {
                            text: 'Google',
                            values: [13.4, 14.11, 14.89, 16.86],
                            alpha: 0.95,
                            borderRadiusTopLeft: '7px',
                            backgroundColor: '#8dd3c7'
                        },
                        {
                            text: 'Microsoft',
                            values: [18.53, 24.52, 20.4, 23.38],
                            borderRadiusTopLeft: '7px',
                            alpha: 0.95,
                            backgroundColor: '#fb8072'
                        },
                        {
                            text: 'Amazon',
                            values: [17.09, 25.59, 19.74, 19.34],
                            borderRadiusTopLeft: '7px',
                            alpha: 0.95,
                            backgroundColor: '#80b1d3'
                        },
                        {
                            text: 'Cognizant',
                            values: [2.31, 2.36, 2.42, 2.52],
                            borderRadiusTopLeft: '7px',
                            alpha: 0.95,
                            backgroundColor: '#b3de69'
                        }
                    ]
                }

            },

            expensesChartxxx: function() {
                return {
                    type: 'bar',
                    globals: {
                        fontFamily: 'Cairo',
                        fontSize: '14px',
                    },
                    legend: {
                        cursor: 'hand',
                        draggable: true,
                        dragHandler: 'icon',
                        toggleAction: 'remove',
                        minimize: true,
                        header: {
                            height: '20px',
                            text: 'Expenses Breakdown'
                        },
                        backgroundColor: 'white',
                    },
                    plotarea: {
                        margin: '50px dynamic dynamic dynamic'
                    },
                    // plot represents general series, or plots, styling
                    plot: {
                        stacked: true,
                        // hoverstate
                        tooltip: {
                            visible: false
                        },
                        // animation docs here:
                        // https://www.zingchart.com/docs/tutorials/design-and-styling/chart-animation/#animation__effect
                        animation: {
                            effect: 'ANIMATION_SLIDE_TOP',
                            method: 'ANIMATION_BOUNCE_EASE_OUT',
                            sequence: 'ANIMATION_NO_SEQUENCE',
                            speed: 975
                        },
                        lineWidth: '3px',
                        // line node styling
                        marker: {
                            borderWidth: '0px',
                            size: '6px'
                        }
                    },
                    scaleX: {
                        // set scale label
                        label: {
                            text: 'Days'
                        },
                        // convert text on scale indices
                        labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
                    },
                    scaleY: {
                        // scale label with unicode character
                        label: {
                            text: 'Work Productivity'
                        },
                        format: '%v%'
                    },
                    crosshairX: {
                        plotLabel: {
                            padding: '10px 15px',
                            borderRadius: '3px',
                            sortByValue: 'asc'
                        },
                        lineWidth: '100%',
                        alpha: .28,
                    },
                    series: [{
                        text: 'Working',
                        // plot values
                        values: [20, 30, 50, 50, 40, 0, 0],
                        backgroundColor: '#b4d2f4',
                    },
                        {
                            text: 'Finding a New Zoom Filter',
                            // plot values
                            values: [60, 30, 0, 20, 0, 0, 0],
                            backgroundColor: '#ffe0b7',
                        },
                        {
                            text: 'Feeding my Kids',
                            // plot values
                            values: [20, 30, 30, 20, 30, 10, 20],
                            backgroundColor: '#8caf89',
                        },
                        {
                            text: 'Drinking Alcohol',
                            // plot values
                            values: [0, 10, 20, 10, 30, 90, 80],
                            backgroundColor: '#ffbb9a',
                        }
                    ]
                }

            },

        },
        methods: {
            fillData () {
                this.chartData = {
                    labels: [this.getRandomInt(), this.getRandomInt()],
                    datasets: [
                        {
                            label: 'Data One',
                            backgroundColor: '#f87979',
                            data: [this.getRandomInt(), this.getRandomInt()]
                        }, {
                            label: 'Data One',
                            backgroundColor: '#f87979',
                            data: [this.getRandomInt(), this.getRandomInt()]
                        }
                    ]
                }
            },

            formatNumber(num) {
                return Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'k' : Math.sign(num)*Math.abs(num)
            },

            formatDifference(newNum, oldNum){
                let difference = 0;
                let arrow = '<i class="fas fa-caret-up" style="color: #008a18;"></i>';
                let percentage = 0;
                if(newNum > -1 && oldNum > -1){
                    difference = newNum - oldNum;
                    if(difference < 0){arrow = '<i class="fas fa-caret-down" style="color: red;"></i>'}
                    percentage = (Math.round((difference)/oldNum*100))
                }
                return {difference: difference, arrow: arrow, percentage: percentage};
            },

            getRandomInt () {
                return Math.floor(Math.random() * (50 - 5 + 1)) + 5
            }
        }
    }
</script>

<style>
  .small {
    max-width: 600px;
    margin:  150px auto;
  }
</style>